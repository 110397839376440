import React from 'react'

import { theme } from '../../constants/theme'

const ScrollDown = ({ color = theme.backgroundHighlight, className }) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.2543 24.4316C15.9862 24.4316 15.7323 24.324 15.5485 24.1324L9.66871 18.0028C9.28601 17.6039 9.30557 16.957 9.71242 16.5581C10.1193 16.1591 10.7593 16.1591 11.142 16.5581L15.3489 20.9437L15.707 9.10766C15.7241 8.54345 16.1905 8.08607 16.7488 8.08607C17.3071 8.08607 17.7458 8.54345 17.7288 9.10766L17.3707 20.9437L21.843 16.5581C22.2498 16.1591 22.8899 16.1591 23.2726 16.5581C23.6553 16.957 23.6357 17.6039 23.2289 18.0028L16.9781 24.1324C16.7828 24.324 16.5224 24.4316 16.2543 24.4316Z"
      fill={color}
    />
    <circle cx="16.5" cy="16.5005" r="16" stroke="#ffffff3b" />
    <circle
      cx="16.5"
      cy="16.5005"
      r="16"
      stroke={color}
      className="scroll-progress"
      id="scroll-progress"
    />
  </svg>
)

export default ScrollDown
