import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const HomeStyled = styled.div`
  height: 100%;

  .home-content {
    position: relative;
    overflow-x: hidden;
  }

  .section-tickets {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 30vh 0 60vh;

    @media (max-width: ${breakpoints.screenLG}) {
      flex-direction: column-reverse;
      margin: 0 0 100px;
    }

    .content {
      position: absolute;
      top: -15%;
      left: 29.5vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      z-index: 2;

      @media (max-width: ${breakpoints.screenLG}) {
        position: relative;
        top: 0;
        left: 0;
        padding: 5% 10vw 2% 12vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        padding: 10% 15vw 0 15vw;
      }
    }

    .text {
      opacity: 0;
      transform: translateY(20vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      &.text-2 {
        transition-delay: 0.1s;
      }
    }

    .icon {
      display: none;
      position: absolute;
      left: 20px;
      top: 50px;
      width: 50px;
      height: 50px;
      transform: rotate(35deg);

      @media (max-width: ${breakpoints.screenLG}) {
        display: block;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        left: 12px;
        width: 34px;
        height: 34px;
      }
    }

    .img {
      position: relative;
      display: flex;
      background-color: ${({ theme }) => theme.backgroundDark};
      width: 75.5vw;
      opacity: 0.4;
      will-change: opacity;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 140%;
        max-width: 140%;
      }

      &.reveal {
        opacity: 0.9;
      }

      img {
        mix-blend-mode: difference;
      }
    }
  }

  .section-lineup {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50vh;

    @media (max-width: ${breakpoints.screenLG}) {
      flex-direction: column-reverse;
      margin-bottom: 150px;
    }

    .content {
      position: absolute;
      top: -15%;
      left: 11vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      z-index: 2;

      @media (max-width: ${breakpoints.screenLG}) {
        position: relative;
        top: 0;
        left: 0;
        padding: 5% 10vw 2% 12vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        padding: 10% 15vw 0 15vw;
      }
    }

    .text {
      opacity: 0;
      transform: translateY(20vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .icon {
      display: none;
      position: absolute;
      left: 20px;
      top: 50px;
      width: 50px;
      height: 50px;
      transform: rotate(35deg);

      @media (max-width: ${breakpoints.screenLG}) {
        display: block;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        left: 12px;
        width: 34px;
        height: 34px;
      }
    }

    .img {
      position: relative;
      display: flex;
      background-color: ${({ theme }) => theme.backgroundDark};
      width: 75.5vw;
      opacity: 0.4;
      will-change: opacity;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 140%;
        max-width: 140%;
      }

      &.reveal {
        opacity: 0.9;
      }

      img {
        mix-blend-mode: difference;
      }
    }
  }

  .section-a-to-z {
    position: relative;
    margin-bottom: 40vh;

    @media (max-width: ${breakpoints.screenLG}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      padding: 0 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 0 20px;
    }

    .content {
      position: absolute;
      top: 29%;
      left: 36vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        position: relative;
        top: auto;
        left: auto;
        margin: 40px 0 20px;
      }
    }

    .text {
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      :nth-child(2) {
        transition-delay: 0.1s;
      }
    }

    .btn {
      position: absolute;
      left: 12vw;
      bottom: 7.5%;
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.2s;

      @media (max-width: ${breakpoints.screenLG}) {
        left: auto;
        right: 0;
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      svg {
        width: 1.6vw;
        height: auto;

        @media (max-width: ${breakpoints.screenLG}) {
          width: auto;
        }
      }
    }

    .img {
      width: 25vw;
      margin-left: 15vw;
      opacity: 0.95;
      will-change: opacity;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      overflow: hidden;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 100%;
        margin-left: 0;
      }

      &.reveal {
        opacity: 0.8;
      }
    }

    .social-img {
      width: 100%;
    }
  }

  .section-soundcloud {
    position: relative;
    margin-bottom: 40vh;

    @media (max-width: ${breakpoints.screenLG}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      padding: 0 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 0 20px;
    }

    .controller {
      display: flex;
      width: 100%;

      @media (max-width: ${breakpoints.screenLG}) {
        position: absolute;
        top: 10%;
        transform: translateY(-10%);
      }

      @media (max-width: ${breakpoints.screenSM}) {
        top: 20%;
        transform: translateY(-20%);
      }
    }

    .content {
      position: absolute;
      top: 29%;
      left: 60.8vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        position: relative;
        top: auto;
        left: auto;
        margin: 40px 0 20px;
      }
    }

    .text {
      opacity: 0;
      transform: translateY(10vh);
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      :nth-child(2) {
        transition-delay: 0.1s;
      }
    }

    .btn {
      position: absolute;
      left: 18.2vw;
      bottom: 7.5%;
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.2s;

      @media (max-width: ${breakpoints.screenLG}) {
        left: auto;
        right: 0;
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      svg {
        width: 1.6vw;
        height: auto;

        @media (max-width: ${breakpoints.screenLG}) {
          width: auto;
        }
      }
    }

    .img {
      width: 25vw;
      margin-left: 40vw;
      opacity: 0.95;
      will-change: opacity;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      overflow: hidden;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 100%;
        margin-left: 0;
      }

      &.reveal {
        opacity: 0.8;
      }
    }

    .social-img {
      width: 100%;
    }
  }

  .section-instagram {
    position: relative;
    margin-bottom: 40vh;

    @media (max-width: ${breakpoints.screenLG}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      padding: 0 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 0 20px;
    }

    .content {
      position: absolute;
      top: 29%;
      left: 38vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        position: relative;
        top: auto;
        left: auto;
        margin: 40px 0;
      }
    }

    .text {
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      :nth-child(2) {
        transition-delay: 0.1s;
      }
    }

    .btn {
      position: absolute;
      left: 16.8vw;
      bottom: 7.5%;
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.2s;

      @media (max-width: ${breakpoints.screenLG}) {
        left: auto;
        right: 0;
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      svg {
        width: 1.6vw;
        height: auto;

        @media (max-width: ${breakpoints.screenLG}) {
          width: auto;
        }
      }
    }

    .img {
      width: 25vw;
      margin-left: 15vw;
      opacity: 0.95;
      will-change: opacity;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      overflow: hidden;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 100%;
        margin-left: 0;
      }

      &.reveal {
        opacity: 0.8;
      }
    }

    .social-img {
      width: 100%;
    }
  }

  .section-facebook {
    position: relative;
    margin-bottom: 60vh;

    @media (max-width: ${breakpoints.screenLG}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 150px;
      padding: 0 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 0 20px;
    }

    .content {
      position: absolute;
      top: 29%;
      left: 58.8vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        position: relative;
        top: auto;
        left: auto;
        margin: 40px 0 20px;
      }
    }

    .text {
      opacity: 0;
      transform: translateY(10vh);
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      :nth-child(2) {
        transition-delay: 0.1s;
      }
    }

    .btn {
      position: absolute;
      left: 16.8vw;
      bottom: 7.5%;
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.2s;

      @media (max-width: ${breakpoints.screenLG}) {
        left: auto;
        right: 0;
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      svg {
        width: 1.6vw;
        height: auto;

        @media (max-width: ${breakpoints.screenLG}) {
          width: auto;
        }
      }
    }

    .img {
      width: 25vw;
      margin-left: 40vw;
      opacity: 0.95;
      will-change: opacity;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      overflow: hidden;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 100%;
        margin-left: 0;
      }

      &.reveal {
        opacity: 0.8;
      }
    }

    .social-img {
      width: 100%;
    }
  }

  .section-youtube {
    margin-bottom: 40vh;

    @media (max-width: ${breakpoints.screenLG}) {
      margin-bottom: 200px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      margin-bottom: 150px;
    }

    .marquee {
      position: relative;
      display: flex;
      gap: 6vw;
      z-index: 2;
      opacity: 0;
      will-change: opacity, transform;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      &.reveal {
        opacity: 0.85;
      }
    }

    .text {
      position: relative;
      font-size: 13vw;

      @media (max-width: ${breakpoints.screenLG}) {
        font-size: 130px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        font-size: 120px;
      }
    }

    .video {
      position: relative;
      background-color: ${({ theme }) => theme.backgroundDark};
      width: 100%;
      margin-top: -6%;
      opacity: 0;
      will-change: opacity;
      transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      z-index: 1;

      &.reveal {
        opacity: 0.85;
      }

      &.hide-lg {
        display: none;

        @media (max-width: ${breakpoints.screenLG}) {
          display: block;
        }
      }
    }

    video {
      mix-blend-mode: difference;

      @media (max-width: ${breakpoints.screenLG}) {
        width: 200%;
      }
    }
  }

  .section-sustainability {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 13vw;
    margin-bottom: 30vh;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 0 30px;
      margin-bottom: 50px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 0 20px;
      margin-bottom: 0;
    }

    .content:nth-child(1) {
      margin-bottom: 10vh;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 100px;
      }
    }

    .content:nth-child(2) {
      margin-left: auto;
    }

    .text {
      text-indent: 1.8em;
      margin-bottom: 8%;
      opacity: 0;
      transform: translateY(13vh);
      will-change: transform, opacity;
      transition: transform 1s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        transform: translateY(6vh);
        margin-bottom: 30px;

        br {
          display: none;
        }
      }

      @media (max-width: ${breakpoints.screenSM}) {
        text-indent: 1em;
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .link {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      opacity: 0;
      transform: translateY(13vh);
      will-change: transform, opacity;
      transition: transform 1s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.1s;

      @media (max-width: ${breakpoints.screenLG}) {
        transform: translateY(6vh);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      svg {
        width: 2.2vw;
        height: auto;

        @media (max-width: ${breakpoints.screenLG}) {
          width: auto;
        }
      }
    }
  }
`

export { HomeStyled }
