import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Controller, Scene } from 'react-scrollmagic'
import { useTheme } from 'styled-components'

import imgTickets from '../assets/img-tickets.webp'
import imgLineup from '../assets/img-lineup.webp'
import imgAtoZ from '../assets/img-a-to-z.webp'
import imgSoundcloud from '../assets/img-soundcloud.webp'
import imgInstagram from '../assets/img-instagram.webp'
import imgFacebook from '../assets/img-facebook.webp'
import videoMp4 from '../assets/butik-video.mp4'

import SEO from '../components/SEO'
import Header from '../components/Header'
import CoverDefault from '../components/CoverDefault'
import Button from '../components/Button'
import Footer from '../components/Footer'
import ArrowUpRight from '../components/icons/ArrowUpRight'
import Plus from '../components/icons/Plus'

import { PageLayout } from '../styles/Layout'
import { HomeStyled } from '../styles/HomeStyled'
import { Headline, TitleL, BodyL, BodyM } from '../styles/Typography'

const Home = () => {
  const theme = useTheme()

  const [isReverse, setIsReverse] = useState(false)
  const [videoMuted, setVideoMuted] = useState(true)
  const [showVideo, setShowVideo] = useState(false)

  const handleVideoClick = () => {
    const pointer = document.querySelector('.video-pointer')

    pointer.innerText = videoMuted ? 'Sound Off' : 'Sound On'
    setVideoMuted(!videoMuted)
  }

  const changeBackgroundColor = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    let sectionAtoZPosition
    let sectionYouTubePosition

    if (window.innerWidth < 1200) {
      sectionAtoZPosition =
        document.querySelector('.section-a-to-z')?.offsetTop + 550
      sectionYouTubePosition =
        document.querySelector('.section-youtube')?.offsetTop + 250
    } else {
      sectionAtoZPosition =
        document.querySelector('.section-a-to-z')?.offsetTop + 50
      sectionYouTubePosition =
        document.querySelector('.section-youtube')?.offsetTop + 60
    }

    const pageHeight = document.body.offsetHeight - (window.innerHeight + 300)

    if (
      (windowScroll >= sectionAtoZPosition &&
        windowScroll <= sectionYouTubePosition) ||
      windowScroll >= pageHeight
    ) {
      setIsReverse(true)
    } else {
      setIsReverse(false)
    }
  }

  const moveMarquee = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const marquee = document.querySelector('.marquee')
    if (marquee) {
      const marqueeOffset = marquee.offsetTop

      if (window.innerWidth < 1200) {
        marquee.style.transform = `translateX(-${
          (windowScroll - marqueeOffset) / 5
        }%)`
      } else {
        marquee.style.transform = `translateX(-${
          (windowScroll - marqueeOffset) / 20
        }%)`
      }
    }
  }

  const handleShowVideo = () => {
    if (window.innerWidth < 1200) {
      setShowVideo(false)
    } else {
      setShowVideo(true)
    }
  }

  const moveSocialImages = () => {
    const socialImages = document.querySelectorAll('.social-img')
    socialImages?.forEach((image) => {
      const imageOffsetTop = image.getBoundingClientRect().top
      image.style.transform = `scale(${
        1.0 + (imageOffsetTop - window.innerHeight) / -6000
      })`

      // + parallax example
      // image.style.transform = `translateY(${(imageOffsetTop - window.innerHeight) / 150}%)`
    })
  }

  const onScroll = () => {
    window.requestAnimationFrame(changeBackgroundColor)
    window.requestAnimationFrame(moveMarquee)
    window.requestAnimationFrame(handleShowVideo)
    window.requestAnimationFrame(moveSocialImages)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <SEO
        title="Butik • Electronic Music Festival"
        description="Butik Electronic Music Festival"
        image={imgTickets}
      />

      <PageLayout
        backgroundColor={
          isReverse ? theme.backgroundPrimary : theme.backgroundDark
        }
      >
        <Header
          textColor={
            isReverse ? theme.backgroundDark : theme.backgroundHighlight
          }
          logoColor={
            isReverse ? theme.backgroundDark : theme.backgroundHighlight
          }
        />
        <HomeStyled>
          <CoverDefault />

          <div className="home-content">
            <div className="section-tickets" id="section-tickets">
              <Controller>
                <a
                  href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0"
                  target="_blank"
                >
                  <div className="content">
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-tickets"
                      offset={-200}
                      reverse={true}
                    >
                      <Headline
                        className="text text-1"
                        color={theme.textReverse}
                      >
                        Tickets
                      </Headline>
                    </Scene>
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-tickets"
                      offset={-200}
                      reverse={true}
                    >
                      <Headline
                        className="text text-2"
                        color={theme.textReverse}
                      >
                        sold out
                      </Headline>
                    </Scene>
                    <ArrowUpRight color={theme.textReverse} className="icon" />
                  </div>
                </a>
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-tickets"
                  offset={-200}
                  reverse={true}
                >
                  <div className="img">
                    <img src={imgTickets} alt="Butik Tickets" />
                  </div>
                </Scene>
              </Controller>
            </div>

            <div className="section-lineup" id="section-lineup">
              <Controller>
                <Link to="/lineup">
                  <div className="content">
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-lineup"
                      offset={-300}
                      reverse={true}
                    >
                      <Headline className="text" color={theme.textReverse}>
                        Lineup
                      </Headline>
                    </Scene>
                    <ArrowUpRight color={theme.textReverse} className="icon" />
                  </div>
                </Link>
                <div className="img-group">
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-lineup"
                    offset={-300}
                    reverse={true}
                  >
                    <div className="img">
                      <img src={imgLineup} alt="Butik Lineup" />
                    </div>
                  </Scene>
                </div>
              </Controller>
            </div>

            <div className="section-a-to-z">
              <div className="controller" id="section-a-to-z"></div>
              <Controller>
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-a-to-z"
                  reverse={true}
                >
                  <div className="img">
                    <img
                      className="social-img"
                      src={imgAtoZ}
                      alt="Butik A to Z"
                    />
                  </div>
                </Scene>
                <div className="content">
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-a-to-z"
                    reverse={true}
                  >
                    <TitleL className="text">Festival</TitleL>
                  </Scene>
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-a-to-z"
                    reverse={true}
                  >
                    <TitleL className="text">A-Z</TitleL>
                  </Scene>
                  <Link to="/festival-a-z">
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-a-to-z"
                      reverse={true}
                    >
                      <div className="btn">
                        <Button>
                          <Plus />
                        </Button>
                      </div>
                    </Scene>
                  </Link>
                </div>
              </Controller>
            </div>

            <div className="section-soundcloud" id="section-soundcloud">
              <Controller>
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-soundcloud"
                  reverse={true}
                >
                  <div className="img">
                    <img
                      className="social-img"
                      src={imgSoundcloud}
                      alt="Butik Soundcloud"
                    />
                  </div>
                </Scene>
                <div className="content">
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-soundcloud"
                    reverse={true}
                  >
                    <TitleL className="text">Sound</TitleL>
                  </Scene>
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-soundcloud"
                    reverse={true}
                  >
                    <TitleL className="text">cloud</TitleL>
                  </Scene>
                  <a
                    href="https://soundcloud.com/butikfestival"
                    target="_blank"
                  >
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-soundcloud"
                      reverse={true}
                    >
                      <div className="btn">
                        <Button>
                          <Plus />
                        </Button>
                      </div>
                    </Scene>
                  </a>
                </div>
              </Controller>
            </div>

            <div className="section-instagram" id="section-instagram">
              <Controller>
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-instagram"
                  reverse={true}
                >
                  <div className="img">
                    <img
                      className="social-img"
                      src={imgInstagram}
                      alt="Butik Instagram"
                    />
                  </div>
                </Scene>
                <div className="content">
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-instagram"
                    reverse={true}
                  >
                    <TitleL className="text">Insta</TitleL>
                  </Scene>
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-instagram"
                    reverse={true}
                  >
                    <TitleL className="text">gram</TitleL>
                  </Scene>
                  <a href="https://www.instagram.com/butik_emf" target="_blank">
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-instagram"
                      reverse={true}
                    >
                      <div className="btn">
                        <Button>
                          <Plus />
                        </Button>
                      </div>
                    </Scene>
                  </a>
                </div>
              </Controller>
            </div>

            <div className="section-facebook" id="section-facebook">
              <Controller>
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-facebook"
                  reverse={true}
                >
                  <div className="img">
                    <img
                      className="social-img"
                      src={imgFacebook}
                      alt="Butik Facebook"
                    />
                  </div>
                </Scene>
                <div className="content">
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-facebook"
                    reverse={true}
                  >
                    <TitleL className="text">Face</TitleL>
                  </Scene>
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-facebook"
                    reverse={true}
                  >
                    <TitleL className="text">book</TitleL>
                  </Scene>
                  <a
                    href="https://www.facebook.com/butikfestival"
                    target="_blank"
                  >
                    <Scene
                      classToggle="reveal"
                      triggerElement="#section-facebook"
                      reverse={true}
                    >
                      <div className="btn">
                        <Button>
                          <Plus />
                        </Button>
                      </div>
                    </Scene>
                  </a>
                </div>
              </Controller>
            </div>

            <div className="section-youtube" id="section-youtube">
              <Controller>
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-youtube"
                  offset={-280}
                  reverse={true}
                >
                  <div className="marquee">
                    <a
                      href="https://www.youtube.com/@butikfestival"
                      target="_blank"
                    >
                      <Headline className="text" color={theme.textReverse}>
                        YouTube
                      </Headline>
                    </a>
                    <a
                      href="https://www.youtube.com/@butikfestival"
                      target="_blank"
                    >
                      <Headline className="text" color={theme.textReverse}>
                        YouTube
                      </Headline>
                    </a>
                    <a
                      href="https://www.youtube.com/@butikfestival"
                      target="_blank"
                    >
                      <Headline className="text" color={theme.textReverse}>
                        YouTube
                      </Headline>
                    </a>
                    <a
                      href="https://www.youtube.com/@butikfestival"
                      target="_blank"
                    >
                      <Headline className="text" color={theme.textReverse}>
                        YouTube
                      </Headline>
                    </a>
                  </div>
                </Scene>
                {showVideo && (
                  <Scene
                    classToggle="reveal"
                    triggerElement="#section-youtube"
                    reverse={true}
                  >
                    <div className="video" onClick={handleVideoClick}>
                      <video
                        width="100%"
                        autoPlay
                        muted={videoMuted}
                        playsInline
                        loop
                        alt="Butik EMF"
                      >
                        <source src={videoMp4} type="video/mp4" />
                      </video>
                    </div>
                  </Scene>
                )}
                <Scene
                  classToggle="reveal"
                  triggerElement="#section-youtube"
                  reverse={true}
                >
                  <div className="video hide-lg">
                    <video
                      width="100%"
                      autoPlay
                      muted
                      playsInline
                      loop
                      alt="Butik EMF"
                    >
                      <source src={videoMp4} type="video/mp4" />
                    </video>
                  </div>
                </Scene>
              </Controller>
            </div>

            <div className="section-sustainability">
              <div className="content" id="awareness">
                <Controller>
                  <Scene
                    classToggle="reveal"
                    triggerElement="#awareness"
                    reverse={true}
                  >
                    <BodyL color={theme.textLight} className="text">
                      We want Butik Festival to be <br /> a lovely experience
                      for everyone, so <br /> we encourage for attentive,
                      respectful
                      <br /> and conscious interactions. Butik <br />
                      Festival is supposed to be a place, <br />
                      where everybody is welcome and <br />
                      accepted.
                    </BodyL>
                  </Scene>
                  <Link to="/awareness">
                    <Scene
                      classToggle="reveal"
                      triggerElement="#awareness"
                      reverse={true}
                    >
                      <BodyM
                        className="link"
                        color={theme.textLight}
                        weight={700}
                      >
                        <span>Awareness</span>{' '}
                        <ArrowUpRight color={theme.textLight} />
                      </BodyM>
                    </Scene>
                  </Link>
                </Controller>
              </div>

              <div className="content" id="sustainability">
                <Controller>
                  <Scene
                    classToggle="reveal"
                    triggerElement="#sustainability"
                    reverse={true}
                  >
                    <BodyL color={theme.textLight} className="text">
                      Our festival site is probably the most <br />
                      spectacular location in Europe. It takes <br />
                      place in beautiful nature and is impacting
                      <br /> life of the small local community. This is <br />
                      why we want to do everything we can to <br />
                      respect nature and locals. Thank you for
                      <br /> your cooperation.
                    </BodyL>
                  </Scene>
                  <Link to="/sustainability">
                    <Scene
                      classToggle="reveal"
                      triggerElement="#sustainability"
                      reverse={true}
                    >
                      <BodyM
                        className="link"
                        color={theme.textLight}
                        weight={700}
                      >
                        <span>Sustainability</span>{' '}
                        <ArrowUpRight color={theme.textLight} />
                      </BodyM>
                    </Scene>
                  </Link>
                </Controller>
              </div>
            </div>
            <Footer isFadedOut={!isReverse} />
          </div>
        </HomeStyled>
      </PageLayout>
    </>
  )
}

export default Home
